<!--desc:渠道接入-网站接入-设置页面-->
<template>
    <div class="bot-link-set">
        <content-header
            :headerTitle="headerTitle"
            :isAutoBack="false"
            :showHelp="false"
            @goBack="goBack">
        </content-header>
        <div class="bot-link-set-content">
            <el-menu
                @select="handleClick"
                :default-active="'entrance'"
                class="el-menu-vertical-demo el-menu-vertical-bot-intent">
                <el-menu-item index="entrance" style="min-width: 160px;padding-left: 30px" >
                    <span slot="title"><i class="iconfont guoran-tongyichicun-xingxiang"></i>网站入口形象</span>
                </el-menu-item>
                <el-menu-item index="app" style="min-width: 160px;padding-left: 30px" >
                    <span slot="title"><i class="iconfont guoran-tongyichicun-yingyongxingxiang"></i>应用内形象</span>
                </el-menu-item>
            </el-menu>
            <div class="bot-link-set-content-right">
                <!-- 网站入口形象 -->
                <template v-if="currentTab === 'entrance'">
                    <div class="entrance-content">
                        <div class="bottom-save">
                            <el-button
                                size="medium"
                                round
                                type="primary"
                                @click="save"
                                icon="guoran-tongyichicun-16-03-baocun iconfont"
                                >保存</el-button>
                        </div>
                        <div class="title">网站入口形象</div>
                        <div class="static-img">
                            <el-upload
                                class="avatar-uploader"
                                accept=".jpg,.png,.jpeg,.gif"
                                :action="requestUrl.webhook.uploadFile"
                                :show-file-list="false"
                                :on-success="(response,file,fileList)=>handleAvatarSuccess(response,file,fileList,'entrance')"
                                :before-upload="beforeAvatarUpload">
                                <span v-if="!isShowUpload" class="nav_icon_view" @mouseenter="mouseenter" @mouseleave="mouseleave">
                                    <svgOrIcon
                                        :icon="entranceIcon"
                                        iconClass="iconfont"
                                    ></svgOrIcon>
                                    <span class="avatar-uploader-icon" v-show="isShowMark && entranceIcon !== ''">重新上传</span>
                                    <!-- <i v-show="isShowMark && entranceIcon !== ''" class="el-icon-delete avatar-uploader-icon" @click.stop="delImg('entrance')"></i> -->
                                </span>
                                <div v-if="isShowUpload" class="el-icon-plus avatar-uploader-icon"></div>
                            </el-upload>
                            <div class="right-text">
                                <div class="top-text">静态时形象</div>
                                <div class="bottom-text">形象的大小将与上传的图片尺寸保持一致，建议使用48px-64px范围内的图片，支持动态图</div>
                            </div>
                        </div>
                        <div class="hover-img">
                            <el-upload
                                class="avatar-uploader"
                                accept=".jpg,.png,.jpeg,.gif"
                                :action="requestUrl.webhook.uploadFile"
                                :show-file-list="false"
                                :on-success="(response,file,fileList)=>handleAvatarSuccess(response,file,fileList,'entrance-hover')"
                                :before-upload="beforeAvatarUpload">
                                <span v-if="!isShowUploadHover" class="nav_icon_view" @mouseenter="mouseenter('entrance-hover')" @mouseleave="mouseleave('entrance-hover')">
                                    <svgOrIcon
                                        :icon="hoverEntranceIcon"
                                        iconClass="iconfont"
                                    ></svgOrIcon>
                                    <span class="avatar-uploader-icon" v-show="isShowMarkHover && hoverEntranceIcon !== ''">重新上传</span>
                                    <!-- <i v-show="isShowMarkHover && hoverEntranceIcon !== ''" class="el-icon-delete avatar-uploader-icon" @click.stop="delImg('entrance-hover')"></i> -->
                                </span>
                                <div v-if="isShowUploadHover" class="el-icon-plus avatar-uploader-icon"></div>
                            </el-upload>
                            <div class="right-text">
                                <div class="top-text">鼠标悬浮时形象</div>
                                <div class="bottom-text">形象的大小将与上传的图片尺寸保持一致，建议使用48px-64px范围内的图片，支持动态图</div>
                            </div>
                        </div>
                        <div class="img-list">
                            <div :class="['img-box-item',item.checked ? 'checked' : '']"  v-for="(item,index) in defaultAgentLogoList" :key="item.id" @click="chooseDefaultImg(item,index)">
                                <el-image
                                    :src="item.agentLogoUrl"
                                    :fit="'cover'">
                                </el-image>
                                <div class="triangle-bottomright" v-if="item.checked"></div>
                                <i class="el-icon-check" v-if="item.checked"></i>

                           </div>
                        </div>
                        <div class="entrance-init-position">
                            <div class="right-text">
                                <div class="top-text">入口初始位置</div>
                                <div class="bottom-text">可直接拖动入口图标设置入口的初始位置</div>
                            </div>
                        </div>
                        <div class="entrance-size">
                            <div class="right-text">
                                <div class="top-text">入口形象大小</div>
                                <div class="bottom-text">
                                    <div>宽 <el-input v-model.trim.number="width" placeholder="请输入内容"  @input="changeSize($event,'width')"><i slot="suffix" style="font-style:normal;">px</i></el-input></div>
                                    <div>高 <el-input v-model.trim.number="height" placeholder="请输入内容"  @input="changeSize($event,'height')"><i slot="suffix" style="font-style:normal;">px</i></el-input></div>
                                </div>
                            </div>
                        </div>
                        <div 
                            @mouseenter="mouseenterWebImg"
                            @mouseleave="mouseleaverWebImg"
                            class="entrance-move-img" 
                            :style="{width:width+'px',height:height+'px',right:xcoordinate+'px',bottom:ycoordinate+'px',backgroundImage:'url(' + entranceIcon + ')',backgroundSize:width+'px' + ' ' + height+'px'}">
                        </div>
                    </div>
                </template>
                <!-- 应用内形象 -->
                <template v-if="currentTab === 'app'">
                    <div class="app-content">
                        <div class="left-content">
                            <div class="bottom-save">
                                <el-button
                                    size="medium"
                                    round
                                    type="primary"
                                    @click="save"
                                    icon="guoran-tongyichicun-16-03-baocun iconfont"
                                    >保存</el-button>
                            </div>
                            <div class="title">应用内形象</div>
                            <div class="first-img">
                                <el-upload
                                    accept=".jpg,.png,.jpeg,.gif,.svg"
                                    class="avatar-uploader"
                                    :action="requestUrl.webhook.uploadFile"
                                    :show-file-list="false"
                                    :on-success="handleAvatarSuccessApp"
                                    :before-upload="beforeAvatarUploadApp">
                                    <span v-if="!isShowUpload" class="nav_icon_view" @mouseenter="mouseenter" @mouseleave="mouseleave">
                                        <svgOrIcon
                                            :icon="appIcon"
                                            iconClass="iconfont"
                                        ></svgOrIcon>
                                        <span class="avatar-uploader-icon" v-show="isShowMark && appIcon !== ''">重新上传</span>
                                        <!-- <i v-show="isShowMark && appIcon !== ''" class="el-icon-delete avatar-uploader-icon" @click.stop="delImg('app')"></i> -->
                                    </span>
                                    <div v-if="isShowUpload" class="el-icon-plus avatar-uploader-icon"></div>
                                </el-upload>
                                <div class="app-text">
                                    应用内形象最好上传200px x 200px的正方形图标，您可以在
                                    <a href="https://www.iconfont.cn/" target="_blank">iconfont</a>
                                    上寻找您想要的图标
                                </div>
                            </div>
                             <div class="img-list">
                                <div :class="['img-box-item',item.checked ? 'checked' : '']"  v-for="(item,index) in defaultAgentLogoList" :key="item.id" @click="chooseDefaultImgApp(item,index)">
                                        <el-image
                                            :src="item.agentLogoUrl"
                                            :fit="'cover'">
                                        </el-image>
                                        <div class="triangle-bottomright" v-if="item.checked"></div>
                                        <i class="el-icon-check" v-if="item.checked"></i>
                                </div>
                            </div>
                       </div>
                       <div class="right-phone">
                           <div class="guess-qa">
                            <svgOrIcon
                                class="bot-icon"
                                :icon="appIcon"
                                :isSpecial="appIcon.indexOf('2.png')!==-1 || appIcon.indexOf('5.png')!==-1|| appIcon.indexOf('6.png')!==-1"
                                iconClass="iconfont"
                                type="app"
                                :theDefault="checkedImg.theDefault ? checkedImg.theDefault : false"
                            ></svgOrIcon>
                            <div class="right-content">
                                <div class="right-list">
                                    <div class="jiao-recommend"></div>
                                    <div class="text">您好，AskBot很高兴为您服务!</div>
                                </div>
                            </div>
                            </div>
                            <div class="bottom-config">
                                <div class="bottom-config-set">
                                    <div class="speak iconfont guoran-arsenalchess-pawn-solid1"></div>
                                    <div class="input">请输入文字</div>
                                    <div class="add-btn iconfont guoran-tongyichicun-16-13-xinjian"></div>
                                </div>
                            </div>
                       </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import contentHeader from "../../components/content-top-header.vue";
import svgOrIcon from './component/svgOrIcon.vue';
import { getFileSuffix } from "../../utils/AliyunlssUtils";
export default {
    components:{contentHeader,svgOrIcon},
    data(){
        return{
            headerTitle:'',
            currentTab:'entrance',
            defaultAgentLogoList:[],
            width:45,
            height:45,
            appIcon:'',
            entranceIcon:'',
            hoverEntranceIcon:'',
            isShowUpload:false,
            isShowMark:false,
            xcoordinate:100,
            ycoordinate:100,
            checkedImg:{},
            hoverCheckedImg:{},
            id:null,
            isShowMarkHover:false,
            isShowUploadHover:false,
            isMove:false,
            // https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F01639c586c91bba801219c77f6efc8.gif&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1667027541&t=d381d2502d4bd2c8592e68ed61dacdb3
        }
    },
    created(){
        this.headerTitle = this.$route.query.webName;
        this.getInfo();
    },
    mounted(){
        this.$nextTick(() => {
            this.dragFn(document.querySelector('.entrance-move-img'));
        })
    },
    methods:{
        // 获取配置信息
        getInfo(){
            this.FetchGet(this.requestUrl.bot.selectPlatformLogoSetByPlatformId+'?platformId='+ this.$route.query.webId).then(res => {
                if (res.code === "0") {
                    console.log(res,9999999);
                    this.id = res.data.id;
                    this.appIcon = res.data.agentLogoUrl;
                    this.entranceIcon = res.data.silentLogoUrl;
                    this.hoverEntranceIcon = res.data.suspendedLogoUrl;
                    this.xcoordinate = res.data.xcoordinate;
                    this.ycoordinate = res.data.ycoordinate;
                    this.width = res.data.entranceLogoWidth;
                    this.height = res.data.entranceLogoHigh;
                    this.getDefaultAgentLogo();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        goBack() {
            this.$router.push({ name:'link',query:{'isback':1}});
        },
        mouseenter(type){
            if(type && type === 'entrance-hover'){
                this.isShowMarkHover = true;
            } else {
                this.isShowMark = true;
            }
        },
        mouseleave(type){
            if(type && type === 'entrance-hover'){
                this.isShowMarkHover = false;
            } else {
                this.isShowMark = false;
            }
        },
        // menu切换
        handleClick(name) {
           this.currentTab = name;
           this.getInfo();
           if(this.currentTab === 'entrance'){
               this.$nextTick(() => {
                    this.dragFn(document.querySelector('.entrance-move-img'));
                })
           }
        //    this.getDefaultAgentLogo();
        },
        // 入口形象大小改变
        changeSize(value,type){
            console.log(type);
            if(Number(value) > 200){
                this.$message.warning("入口形象大小最大为200");
                if(type === 'width'){
                    this.width = 200;
                } else {
                    this.height = 200;
                }
            }
        },
        handleAvatarSuccess(response,file,fileList,type) {
            console.log(response,file,fileList,type);
            if(type === 'entrance'){
                this.entranceIcon = response.data;
                console.log(this.entranceIcon,2222);
                this.isShowUpload = false;
            } else {
                this.hoverEntranceIcon = response.data;
                this.isShowUploadHover = false;
            }
            this.$forceUpdate()
        },
        beforeAvatarUpload(file) {
           if (file) {
                if (!getFileSuffix(file.name, ["png", "jpg", "jpeg",'gif'])) {
                    this.$message.error("请上传png,jpg,jpeg,gif格式的图片");
                    return false;
                }
            }
        },
        handleAvatarSuccessApp(res) {
            this.appIcon = res.data;
            this.isShowUpload = false;
            this.$forceUpdate()
        },
        beforeAvatarUploadApp(file){
            if (!getFileSuffix(file.name, ["png", "jpg", "jpeg",'gif'])) {
                this.$message.error("请上传png,jpg,jpeg,gif格式的图片");
                return false;
            }
        },
        // 重置数据
        resetData(){
            this.defaultAgentLogoList = [];
            this.width = 45;
            this.height = 45;
            this.entranceIcon = '';
            this.appIcon = '';
            this.hoverEntranceIcon = '';
            this.isShowUpload = false;
            this.isShowMark = false;
            this.checkedImg = {};
            this.hoverCheckedImg = {}
        },
        // 获取默认应用内头像/默认网站接入形像, type=1应用内, type=2网站接入 
        getDefaultAgentLogo(){
            let type = this.currentTab === 'entrance' ? 2 : 1;
            this.FetchGet(this.requestUrl.bot.selectDefaultAgentLogo+'?type='+ type).then(res => {
                if (res.code === "0") {
                    res.data.forEach((item,index) => {
                        item.checked = false;
                    })
                    this.defaultAgentLogoList = res.data;
                    this.defaultAgentLogoList.forEach((item,index) => {
                        if(item.agentLogoUrl.indexOf('.svg') !== -1){
                            item.theDefault = false;
                        }
                        if(this.currentTab === 'entrance'){
                            if(item.agentLogoUrl === this.entranceIcon){
                                this.checkedImg = item;
                                item.checked = true;
                            } else {
                                this.checkedImg.theDefault = false;
                                item.checked = false;
                            }
                            if(item.agentLogoUrl === this.hoverEntranceIcon){
                                this.hoverCheckedImg = item;
                                item.checked = true;
                            } else {
                                this.hoverCheckedImg.theDefault = false;
                                item.checked = false;
                            }
                        } else {
                            if(item.agentLogoUrl === this.appIcon){
                                this.checkedImg = item;
                                item.checked = true;
                            } else {
                                this.checkedImg.theDefault = false;
                                item.checked = false;
                            }
                        } 
                    })
                    console.log(this.checkedImg,'checkedImg');
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 应用选择默认头像
        chooseDefaultImg(item,index){
            this.defaultAgentLogoList.forEach((v,i) => {
                v.checked = false;
            })
            item.checked = true;
            this.isShowMark = false;
            if(item.checked){
                this.checkedImg = item;
                this.hoverEntranceIcon = this.entranceIcon = item.agentLogoUrl;
                this.isShowUpload = false;
            } else {
                this.entranceIcon = '';
                this.checkedImg = {};
            }
            console.log(this.checkedImg);
        },
        // app 
        chooseDefaultImgApp(item,index){
            this.defaultAgentLogoList.forEach((v,i) => {
                v.checked = false;
            })
            item.checked = true;
            this.isShowMark = false;
            if(item.checked){
                this.checkedImg = item;
                this.appIcon = item.agentLogoUrl;
                this.isShowUpload = false;
            } else {
                this.appIcon = '';
                this.checkedImg = {};
            }
            console.log(this.checkedImg);
        },
        //拖拽功能
        dragFn(eg) {
            let that = this;
            eg.onmousedown =  (ev) => {
                let e = ev || window.event;
                let eX = e.clientX;
                let eY = e.clientY;
                let disX = eX - eg.offsetLeft;
                let disY = eY - eg.offsetTop;
                document.onmousemove =  (ev) => {
                    this.isMove = true;
                    let e = ev || window.event;
                    let l = e.clientX - disX;
                    let h = e.clientY - disY;
                    let windowWidth = document.documentElement.clientWidth;
                    let windowHeight = document.documentElement.clientHeight;
                    l = that.range(l, 0, windowWidth - eg.offsetWidth);
                    h = that.range(h, 0, windowHeight - eg.offsetHeight);
                    // eg.style.left = `${l}px`;
                    // eg.style.top = `${h}px`;
                    eg.style.right = `${windowWidth-l-eg.offsetWidth}px`;
                    eg.style.bottom = `${windowHeight-h-eg.offsetHeight}px`;
                    console.log(windowWidth-l-eg.offsetWidth,windowHeight-h-eg.offsetHeight);
                }
            }
            document.onmouseup =  () => {
                document.onmousemove = null;
                setTimeout(() => {
                    this.isMove = false;
                }, 300)
            }
        },
        mouseenterWebImg(){
            document.querySelector('.entrance-move-img').style.backgroundImage = "url(" + this.hoverEntranceIcon + ")";
        },
        mouseleaverWebImg(){
            document.querySelector('.entrance-move-img').style.backgroundImage = "url(" + this.entranceIcon + ")";
        },
        //判断边界问题
        range(loc, min, max) {
            if (loc > max) {
                return max;
            } else if (loc < min) {
                return min;
            } else {
                return loc;
            }
        },
        // app
        chooseImgApp(item,index){

        },
        delImg(type){
            if(type === 'entrance'){
                this.entranceIcon = '';
                if(!this.isShowUpload){
                    this.isShowUpload = true;
                }
            } else if (type === 'entrance-hover'){
                this.hoverEntranceIcon = '';
                if(!this.isShowUploadHover){
                    this.isShowUploadHover = true;
                }
            } else {
                this.appIcon = '';
                if(!this.isShowUpload){
                    this.isShowUpload = true;
                }
            }
            if(type === 'app'){
                this.defaultAgentLogoList.forEach((item,index) => {
                    item.checked = false;
                })
            } else {
                this.defaultAgentLogoList.forEach((item,index) => {
                    if(this.entranceIcon !== item.agentLogoUrl && this.hoverEntranceIcon !== item.agentLogoUrl){
                        item.checked = false;
                    } else {
                        if(this.entranceIcon === item.agentLogoUrl){
                            item.checked = true;
                        }
                        if(this.hoverEntranceIcon === item.agentLogoUrl){
                            item.checked = true;
                        }
                    }
                })
            }
        },
       // 保存
        save(){
            let params = {}
            if(this.currentTab === 'app'){
                params = {
                    "agentLogoUrl": this.appIcon,
                    "platformId": this.$route.query.webId,
                    "bindCode":this.$route.query.bindCode
                }
            } else {
                let dom = document.querySelector('.entrance-move-img');
                let windowWidth = document.documentElement.clientWidth;
                let windowHeight = document.documentElement.clientHeight;
                let xcoordinate = windowWidth - dom.getBoundingClientRect().right;
                let ycoordinate = windowHeight - dom.getBoundingClientRect().bottom;
                console.log(dom.getBoundingClientRect());
                console.log(xcoordinate, ycoordinate);
                params = {
                    "bindCode":this.$route.query.bindCode,
                    "suspendedLogoUrl":this.hoverEntranceIcon,
                    "silentLogoUrl": this.entranceIcon,
                    "entranceLogoHigh": this.height,
                    "entranceLogoWidth": this.width,
                    "platformId": this.$route.query.webId,
                    "xcoordinate": xcoordinate < 0 ? 0 : xcoordinate,
                    "ycoordinate": ycoordinate < 0 ? 0 : ycoordinate,
                }
            }
            params.id = this.id;
            this.FetchPost(this.requestUrl.bot.savePlatformLogoSet,params).then(res => {
                if (res.code === "0") {
                    let tips = this.currentTab === 'app' ? '应用内形象' : '网站入口形象'
                    this.$message.success(tips + '设置成功');
                } else {
                    this.$message.error(res.message);
                }
            });
        }
    }
}
</script>

<style lang="less" scoped>
@import './../../assets/less/main/common.less';
@import "./../../assets/less/botIndex/botLinkSet.less";
</style>