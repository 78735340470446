<template>
  <!-- svg 以链接的形式展现 -->
	<div class="svgOrIcon">
		<div v-if="isSpecial" :class="{'is-special':isSpecial,'green':icon.indexOf('2.png')!==-1,'blue':icon.indexOf('5.png')!==-1||icon.indexOf('6.png')!==-1}">
			<el-image
				:class="['icon',type === 'app' ? 'app' : '',theDefault ? 'circle' : '',]"
				v-if="iconType"
				:src="icon"
				:fit="'cover'">
			</el-image>
		</div>
		<template v-else>
			<el-image
					:class="['icon',type === 'app' ? 'app' : '',theDefault ? 'circle' : '',]"
					v-if="iconType"
					:src="icon"
					:fit="'cover'">
			</el-image>
			
			<i v-else :class="[iconClass,icon]"></i>
		</template>
	</div>
   
</template>

<script>
export default {
	props: {
		icon: {
			type: String,
			require: true
		},
		iconClass: {
			type: String,
			require: true
		},
		iconStyle: {
			type: Object
		},
		type:{
			type: String,
			default:"entrance"
		},
		theDefault:{
			type: String,
			default:false
		},
		isSpecial:{
			type: String,
			default:false
		}
	},
	computed: {
		iconType() {
			return (this.icon && this.icon.includes("http") && this.icon.includes("svg")) || this.icon.includes("png") || this.icon.includes("jpg") || this.icon.includes("jpeg")|| this.icon.includes("gif");
		}
	},
	watch:{
		icon(n){
			console.log(n,'nn');
		}
	}
};
</script>

<style lang="less" scoped>
	.svgOrIcon{
		.icon {
			width: 96px;
			height: 96px;
			display: block;
			-webkit-mask-size: contain;
			background-repeat: no-repeat;
			&.app{
				width: 31px;
				height: 33px;
			}
			&.circle{
				// border-radius: 50%;
			}
		}
		.is-special{
			width: 31px;
			height: 33px;
			// &.green{
			// 	background-color: #68DDBD;
			// 	border-radius: 50%;
			// }
			// &.blue{
			// 	background-color: #C2D2FF;
			// 	border-radius: 50%;
			// }
			// .circle{
			// 	border-radius: 50%;
			// }
			// /deep/ img{
			// 	margin-left: 2px;
    		// 	margin-top: 0px;
			// 	border-radius: 50%
			// }

		}
	}
</style>